<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.estate") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 h-full lg:col-span-8">
        <div class="flex h-full flex-col">
          <h2 class="mb-2">{{ t("headings.info") }}</h2>
          <div class="card flex h-full flex-col justify-between p-4">
            <div class="grid grid-cols-1 gap-8 p-4 sm:grid-cols-2">
              <div>
                <img
                  class="aspect-rectangle rounded-md object-cover"
                  :src="getEstateImage(estate)"
                  alt="Estate image"
                />
                <div class="mt-4">
                  <form-filepicker
                    accept="image/jpeg, image/png"
                    :disabled="waiting"
                    id="estate-image-upload"
                    label="change_image"
                    :multiple="false"
                    :textVariant="company.primaryText || 'light'"
                    :variant="company.primary || 'indigo'"
                    v-model="image"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-y-4">
                <div>
                  <label class="block">{{ t("labels.name") }}</label>
                  <span v-if="waiting">
                    <skeleton class="h-5 w-full" />
                  </span>
                  <span v-else>{{ estate.name }}</span>
                </div>
                <div>
                  <label class="block">{{ t("labels.business_id") }}</label>
                  <span v-if="waiting">
                    <skeleton class="h-5 w-full" />
                  </span>
                  <span v-else>{{ estate.businessId }}</span>
                </div>
                <div>
                  <label class="block">{{ t("labels.identifier") }}</label>
                  <span v-if="waiting">
                    <skeleton class="h-5 w-full" />
                  </span>
                  <span v-else>{{ estate.identifier }}</span>
                </div>
                <div>
                  <label class="block">{{
                    t("labels.energy_certificate")
                  }}</label>
                  <span v-if="waiting">
                    <skeleton class="h-5 w-full" />
                  </span>
                  <span v-else>{{
                    getLocaleDate(locale, estate.energyCertificate)
                  }}</span>
                </div>
              </div>
            </div>
            <h3 class="my-2">{{ t("headings.buildings") }}</h3>
            <custom-table
              :fields="buildingFields"
              :items="estate.buildings ? estate.buildings : []"
              :loading="waiting"
              :pagination="false"
              :perPage="10"
              :searchable="false"
              :searchFields="buildingSearchFields"
              :showHeader="true"
              sortBy="name"
              :sortFields="buildingSortFields"
              @row-click="null"
            />
            <div>
              <hr class="my-4 border-slate-300" />
              <div class="flex justify-end">
                <form-button
                  :command="() => router.push(`/estates/${estate.id}/edit`)"
                  :disabled="waiting"
                  id="estate-edit-button"
                  label="edit"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 h-full lg:col-span-4">
        <div class="flex h-full flex-col">
          <h2 class="mb-2">{{ t("headings.contacts") }}</h2>
          <div class="card flex h-full flex-col justify-between p-4">
            <div class="grid grid-cols-1 gap-8 p-4">
              <div>
                <label class="block">{{ t("labels.manager") }}</label>
                <div v-if="waiting" class="flex flex-col gap-2">
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                </div>
                <div v-else-if="manager" class="flex flex-col">
                  <span>{{ manager.lastName }} {{ manager.firstName }}</span>
                  <span>{{ manager.email }}</span>
                  <span>{{ manager.phone }}</span>
                </div>
              </div>
              <div>
                <label class="block">{{ t("labels.chairman") }}</label>
                <div v-if="waiting" class="flex flex-col gap-2">
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                </div>
                <div v-else-if="chairman" class="flex flex-col">
                  <span>{{ chairman.lastName }} {{ chairman.firstName }}</span>
                  <span>{{ chairman.email }}</span>
                  <span>{{ chairman.phone }}</span>
                </div>
              </div>
              <div>
                <label class="block">{{ t("labels.secretary") }}</label>
                <div v-if="waiting" class="flex flex-col gap-2">
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                  <skeleton class="h-5 w-full" />
                </div>
                <div v-else-if="secretary" class="flex flex-col">
                  <span
                    >{{ secretary.lastName }} {{ secretary.firstName }}</span
                  >
                  <span>{{ secretary.email }}</span>
                  <span>{{ secretary.phone }}</span>
                </div>
              </div>
            </div>
            <div>
              <hr class="my-4 border-slate-300" />
              <div class="flex justify-end">
                <form-button
                  :command="
                    () => router.push(`/estates/${estate.id}/contacts/edit`)
                  "
                  :disabled="waiting"
                  id="estate-contact-edit-button"
                  label="edit"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  PARTNERS TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.partner_companies") }}</h2>
        <div class="card">
          <custom-table
            :fields="partnerFields"
            :items="estatePartners"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="partnerSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="partnerSortFields"
            @row-click="(id) => router.push(`/partners/${id}`)"
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="
                    () =>
                      router.push('/estates/' + estate.id + '/partners/edit')
                  "
                  id="header-partner-company-create-button"
                  label="manage"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(types)="{ item }">
              <div class="flex items-center">
                {{
                  item.types
                    .map((partnerType) =>
                      t("partner_types." + getTypeName(partnerType, types))
                    )
                    .join(", ")
                }}
              </div>
            </template>
          </custom-table>
        </div>
      </div>

      <!-- PERSONS TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.persons") }}</h2>
        <div class="card">
          <custom-table
            :fields="personFields"
            :items="estateUsers"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="personSearchFields"
            :showHeader="true"
            sortBy="lastName"
            :sortFields="personSortFields"
            @row-click="
              (id) => router.push(`/estates/${estate.id}/persons/${id}`)
            "
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="
                    () => router.push(`/estates/${estate.id}/persons/create`)
                  "
                  :icon="PlusIcon"
                  id="header-user-create-button"
                  label="create_person"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(lastName)="{ item }">
              <div class="flex items-center">
                <img
                  v-if="item.avatar"
                  class="h-10 w-10 rounded-full object-cover"
                  :src="getAvatar(item)"
                />
                <div
                  v-else
                  class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
                >
                  <UserIcon class="h-3/4 w-3/4 text-white" />
                </div>
                <span class="ml-5"
                  >{{ item.lastName }} {{ item.firstName }}</span
                >
              </div>
            </template>
            <template #cell(apartments)="{ item }">
              {{
                item.estates[estate.id].apartments
                  .map((apartment) => apartment.identifier)
                  .join(", ")
              }}
            </template>
            <template #cell(estates)="{ value }">
              <div class="flex gap-2">
                <div
                  v-for="role in getRoles(value[estate.id].roleId, roles)"
                  :key="role.id"
                >
                  {{ t(`roles.${role.label}`) }}
                </div>
              </div>
            </template>
            <template #cell(status)="{ item }">
              {{ t(`status.${item.estates[estate.id].status}`) }}
            </template>
            <template #cell(actions)="{ item }">
              <div class="flex justify-end gap-2">
                <form-button
                  v-if="
                    item.email && item.estates[estate.id].status != 'active'
                  "
                  :command="() => inviteUser(estate.id, item.id)"
                  :icon="PaperAirplaneIcon"
                  id="header-user-invite-button"
                  label="invite"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
                <form-button
                  :command="() => removeUser(item.id)"
                  :icon="TrashIcon"
                  id="header-user-remove-button"
                  label=""
                  textVariant="light"
                  type="button"
                  variant="danger"
                />
              </div>
            </template>
          </custom-table>
        </div>
      </div>

      <!-- KEYS TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.keys") }}</h2>
        <div class="card">
          <custom-table
            :fields="keyFields"
            :items="keys"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="keySearchFields"
            :showHeader="true"
            sortBy="stamp"
            :sortFields="keySortFields"
            @row-click="(id) => router.push(`/estates/${estate.id}/keys/${id}`)"
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="
                    () => router.push(`/estates/${estate.id}/keys/create`)
                  "
                  :icon="PlusIcon"
                  id="header-key-create-button"
                  label="create_key"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
          </custom-table>
        </div>
      </div>

      <!-- MAINTENANCE REQUESTS TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.maintenance_requests") }}</h2>
        <div class="card">
          <custom-table
            :fields="notificationFields"
            :items="maintenanceRequests"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="notificationSearchFields"
            :showHeader="true"
            sortBy="createdAt"
            :ascending="false"
            :sortFields="notificationSortFields"
            @row-click="
              (id) => router.push(`/estates/${estate.id}/notifications/${id}`)
            "
          >
            <template #cell(estateId)>
              <span>{{ estate.name }}</span>
            </template>
            <template #cell(createdAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(senderId)="{ value }">
              {{ getUserName(value, estateUsers) }}
            </template>
          </custom-table>
        </div>
      </div>

      <!-- METER NOTIFICATION TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.meter_notifications") }}</h2>
        <div class="card">
          <custom-table
            :fields="notificationFields"
            :items="meterNotifications"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="notificationSearchFields"
            :showHeader="true"
            sortBy="createdAt"
            :ascending="false"
            :sortFields="notificationSortFields"
            @row-click="
              (id) => router.push(`/estates/${estate.id}/notifications/${id}`)
            "
          >
            <template #cell(estateId)>
              <span>{{ estate.name }}</span>
            </template>
            <template #cell(createdAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(senderId)="{ value }">
              {{ getUserName(value, estateUsers) }}
            </template>
          </custom-table>
        </div>
      </div>

      <!-- ALTERATION NOTIFICATION TABLE-->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.alteration_notifications") }}</h2>
        <div class="card">
          <custom-table
            :fields="notificationFields"
            :items="alterationNotifications"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="notificationSearchFields"
            :showHeader="true"
            sortBy="createdAt"
            :ascending="false"
            :sortFields="notificationSortFields"
            @row-click="
              (id) => router.push(`/estates/${estate.id}/notifications/${id}`)
            "
          >
            <template #cell(estateId)>
              <span>{{ estate.name }}</span>
            </template>
            <template #cell(createdAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(senderId)="{ value }">
              {{ getUserName(value, estateUsers) }}
            </template>
          </custom-table>
        </div>
      </div>

      <!-- DOCUMENTS TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.documents") }}</h2>
        <div class="card">
          <custom-table
            :fields="documentFields"
            :items="documents"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="documentSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="documentSortFields"
            @row-click="(id) => router.push(`/documents/${id}`)"
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="() => router.push(`/documents/create`)"
                  :icon="PlusIcon"
                  id="header-document-create-button"
                  label="create_document"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(createdAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(updatedAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
          </custom-table>
        </div>
      </div>

      <!-- Files TABLE -->
      <div class="col-span-12">
        <h2 class="mb-2">{{ t("headings.files") }}</h2>
        <div class="card">
          <custom-table
            :fields="fileFields"
            :items="files"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="fileSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="fileSortFields"
          >
            <template #cell(actions)="{ item }">
              <div class="flex justify-end gap-2">
                <form-button
                  :command="() => openFile(item.path)"
                  id="open-file-button"
                  label="open"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
              </div>
            </template>
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  KeyIcon,
  PaperAirplaneIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/vue/24/solid";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormFilepicker from "../components/form/FormFilepicker.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getAvatar, getEstateImage } from "../utils/imageUtils";
import { getRoles } from "../utils/roleUtils";
import { getTagName } from "../utils/tagUtils";
import { getTypeName } from "../utils/typeUtils";
import { getUserName } from "../utils/userUtils";

export default {
  components: {
    Breadcrumb,
    CustomTable,
    FormButton,
    FormFilepicker,
    KeyIcon,
    PaperAirplaneIcon,
    PlusIcon,
    Skeleton,
    TrashIcon,
    UserIcon,
  },
  setup() {
    const { locale, t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const contacts = computed(() => store.state.user.contacts);
    const documents = computed(() => store.state.document.documents);
    const estate = computed(() => store.state.estate.estate);
    const file = computed(() => store.state.file.file);
    const files = computed(() => store.state.file.files);
    const keys = computed(() => store.state.key.keys);
    const notifications = computed(
      () => store.state.notification.notifications
    );
    const estatePartners = computed(() => store.state.partner.partners);
    const estateUsers = computed(() => store.state.user.users);
    const roles = computed(() => store.state.role.roles);
    const tags = computed(() => store.state.file.tags);
    const types = computed(() => store.state.partner.types);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.file.waiting) return true;
      if (store.state.document.waiting) return true;
      if (store.state.key.waiting) return true;
      if (store.state.notification.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const cleaningPartners = computed(() => {
      return partners.value.filter((partner) => partner.type == "cleaning");
    });

    const maintenancePartners = computed(() => {
      return partners.value.filter((partner) => partner.type == "maintenance");
    });

    const wastePartners = computed(() => {
      return partners.value.filter((partner) => partner.type == "waste");
    });

    const chairman = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.chairmanId
      );
    });

    const manager = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.managerId
      );
    });

    const secretary = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.secretaryId
      );
    });

    const maintenanceRequests = computed(() => {
      return notifications.value.filter(
        (notification) => notification.type == "request"
      );
    });

    const meterNotifications = computed(() => {
      return notifications.value.filter(
        (notification) => notification.type == "meter"
      );
    });

    const alterationNotifications = computed(() => {
      return notifications.value.filter(
        (notification) => notification.type == "alteration"
      );
    });

    const formatCertificate = (bool) => {
      if (bool) return t("labels.yes");
      return t("labels.no");
    };

    const buildingFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "address",
        label: "address",
      },
      {
        key: "postalCode",
        label: "postal_code",
      },
      {
        key: "city",
        label: "city",
      },
    ]);
    const buildingSearchFields = ref([]);
    const buildingSortFields = ref(["name"]);

    const partnerFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "types",
        label: "type",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
    ]);
    const partnerSearchFields = ref(["name", "type", "email", "phone"]);
    const partnerSortFields = ref(["name", "type", "email", "phone"]);

    const keyFields = ref([
      {
        key: "stamp",
        label: "stamp",
      },
      {
        key: "loaned",
        label: "loaned",
      },
    ]);
    const keySearchFields = ref(["stamp"]);
    const keySortFields = ref(["stamp"]);

    const personFields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "apartments",
        label: "apartments",
      },
      {
        key: "estates",
        label: "role",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
      {
        key: "status",
        label: "status",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);
    const personSearchFields = ref(["email", "firstName", "lastName", "phone"]);
    const personSortFields = ref(["email", "lastName"]);

    const notificationFields = ref([
      {
        key: "estateId",
        label: "estate_name",
      },
      {
        key: "apartment",
        label: "apartment",
      },
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "senderId",
        label: "sender",
      },
    ]);
    const notificationSearchFields = ref([
      "estateId",
      "apartment",
      "createdAt",
      "senderId",
    ]);
    const notificationSortFields = ref([
      "estateId",
      "apartment",
      "createdAt",
      "senderId",
    ]);

    const documentFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "createdAt",
        label: "created_at",
      },
      {
        key: "updatedAt",
        label: "updated_at",
      },
    ]);
    const documentSearchFields = ref(["createdAt", "name", "updatedAt"]);
    const documentSortFields = ref(["name", "createdAt", "updatedAt"]);

    const fileFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);
    const fileSearchFields = ref(["name", "tag"]);
    const fileSortFields = ref(["name", "tag"]);

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    const image = ref(null);

    const inviteUser = (estateId, userId) => {
      store.dispatch("user/inviteUser", { estateId, userId });
    };

    const removeUser = (id) => {
      console.log(id);
    };

    onMounted(() => {
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
      if (!estate.value.id || estate.value.id != route.params.estateId) {
        store.dispatch("estate/getEstate", route.params.estateId);
      } else {
        const contactIds = [];
        if (estate.value.chairmanId) contactIds.push(estate.value.chairmanId);
        if (estate.value.managerId) contactIds.push(estate.value.managerId);
        if (estate.value.secretaryId) contactIds.push(estate.value.secretaryId);
        if (contactIds.length)
          store.dispatch("user/getEstateContacts", contactIds);
        if (estate.value.partnerIds && estate.value.partnerIds.length)
          store.dispatch("partner/getEstatePartners", estate.value.partnerIds);
        store.dispatch("document/getEstateDocuments", estate.value.id);
        store.dispatch("key/getEstateKeys", estate.value.id);
        store.dispatch("file/getTags");
        store.dispatch("file/getEstateFiles", {
          companyId: user.value.companyId,
          estateId: estate.value.id,
        });
        store.dispatch("partner/getTypes");
        store.dispatch("user/getEstateUsers", estate.value.id);
      }
      if (!notifications.value.length) {
        store.dispatch("notification/getNotifications", user.value.companyId);
      }
    });

    watch(
      () => estate.value,
      (estate) => {
        if (estate.id) {
          const contactIds = [];
          if (estate.chairmanId) contactIds.push(estate.chairmanId);
          if (estate.managerId) contactIds.push(estate.managerId);
          if (estate.secretaryId) contactIds.push(estate.secretaryId);
          if (contactIds.length)
            store.dispatch("user/getEstateContacts", contactIds);
          if (estate.partnerIds && estate.partnerIds.length)
            store.dispatch("partner/getEstatePartners", estate.partnerIds);
          store.dispatch("document/getEstateDocuments", estate.id);
          store.dispatch("file/getTags");
          store.dispatch("file/getEstateFiles", {
            companyId: user.value.companyId,
            estateId: estate.id,
          });
          store.dispatch("key/getEstateKeys", estate.id);
          store.dispatch("user/getEstateUsers", estate.id);
          store.dispatch("partner/getTypes");
        }
      }
    );

    watch(
      () => image.value,
      (image) => {
        if (image) {
          store.dispatch("estate/updateImage", { id: estate.value.id, image });
        }
      }
    );

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    return {
      alterationNotifications,
      buildingFields,
      buildingSearchFields,
      buildingSortFields,
      chairman,
      company: computed(() => store.state.company.company),
      contacts,
      cleaningPartners,
      documentFields,
      documents,
      documentSearchFields,
      documentSortFields,
      estate,
      estatePartners,
      estateUsers,
      fileFields,
      files,
      fileSearchFields,
      fileSortFields,
      formatCertificate,
      getAvatar,
      getEstateImage,
      getLocaleDate,
      getRoles,
      getTagName,
      getTypeName,
      getUserName,
      image,
      inviteUser,
      keyFields,
      keys,
      keySearchFields,
      keySortFields,
      locale,
      maintenancePartners,
      maintenanceRequests,
      manager,
      meterNotifications,
      notificationFields,
      notifications,
      notificationSearchFields,
      notificationSortFields,
      openFile,
      PaperAirplaneIcon,
      partnerFields,
      partnerSearchFields,
      partnerSortFields,
      personFields,
      personSearchFields,
      personSortFields,
      PlusIcon,
      removeUser,
      roles,
      router,
      secretary,
      t,
      tags,
      TrashIcon,
      types,
      waiting,
      wastePartners,
    };
  },
};
</script>
